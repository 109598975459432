import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Container, Row, Col } from "reactstrap";
import Img from "gatsby-image";
import GoogleMap from "../components/googleMap";
import ERotate from "../components/icons/animation/eRotation-orig.js";
import ValueLine from "../components/icons/strategy/strategy-find-value";
import IconsMulti from "../components/icons/strategy/iconMultiFamily";
import IconsCali from "../components/icons/strategy/iconCalifornia";
import SideArrow from "../components/icons/arrows/SideArrow";
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";

const Strategy = ({ data }) => {
  const { page } = data.wpgraphql;
  // eslint-disable-next-line
  let parallax;
  return (
    <Parallax ref={ref => (parallax = ref)}>
      <Layout props="" classNames={`testClass`}>
        <SEO
          title={data.wpgraphql.page.title}
          description={data.wpgraphql.page.acf_page_meta.metadescription}
        />
        <article>
          <header className="header--fullwidth header--lightbg">
            {data.wpgraphql.page.featuredImage != null && (
              <Img
                className="header__image"
                fluid={
                  data.wpgraphql.page.featuredImage.imageFile.childImageSharp
                    .fluid
                }
              />
            )}
            <Container>
              <Row>
                <Col xs={{ offset: 1 }}>
                  <h1 className="heading1">
                    Driving Value for our <span>Investors and Partners</span>
                  </h1>
                </Col>
              </Row>
            </Container>
            <ERotate classname="logo-e" />
          </header>

          <Container>
            <section className="section__intro ">
              <Row>
                <Col
                  xs={{ size: 12, offset: 1 }}
                  md={{ size: 8, offset: 5 }}
                  lg={{ size: 6 }}
                >
                  <h2 className="heading2">{page.acf_strategy.heroSubhead}</h2>
                  <p className="body-copy">{page.acf_strategy.heroText}</p>
                </Col>
              </Row>
            </section>

            <div className="section__multifamilty-focused">
              <Row className="no-gutters">
                <Col
                  className="pl-0"
                  xs={{ size: 12, offset: 1 }}
                  md={{ size: 4, offset: 0 }}
                  lg={{ size: 3, offset: 1 }}
                >
                  <Row className="no-gutters">
                    <Col sm={{ size: 7 }} md={{ size: 14 }}>
                      <h2 className="heading2">
                        {page.acf_strategy.section1.head}
                      </h2>
                      <p className="body-copy">
                        {page.acf_strategy.section1.text}
                      </p>
                    </Col>
                    <Col className="mb-5" sm={{ size: 7 }} md={{ size: 14 }}>
                      {page.acf_strategy.section1.image != null && (
                        <Img
                          className="w-100 mb-50"
                          fixed={
                            page.acf_strategy.section1.image.imageFile
                              .childImageSharp.fixed
                          }
                          alt={page.acf_strategy.section1.image.altText}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col
                  sm={{ size: 12, offset: 1 }}
                  md={{ size: 8, offset: 1 }}
                  lg={{ size: 9, offset: 0 }}
                >
                  <Row>
                    <IconsMulti />
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
          <ParallaxLayer
            offset={-0.04}
            speed={0.15}
            className="parallax__arrow-container d-none d-md-block"
          >
            <SideArrow className="parallax__arrow-right" />
          </ParallaxLayer>
          <Container>
            <div className="section__cali-centric">
              <Row>
                <Col
                  xs={{ size: 12, offset: 1 }}
                  md={{ size: 7, offset: 1 }}
                  lg={{ size: 6, offset: 1 }}
                >
                  <h2 className="heading2 text-yellow">
                    {/* {page.acf_strategy.section2.head} */}
                    Proudly <br /> California-Centric
                  </h2>
                  <p className="body-copy">{page.acf_strategy.section2.text}</p>
                </Col>
              </Row>
              <IconsCali />
            </div>

            <div className="section__property-map mapcontainer">
              <GoogleMap />
            </div>

            <div className="section__how-we-find-value">
              <div className="howvalue-arrow-container"></div>
              {/* <h2>{page.acf_strategy.section3.head}</h2> */}
              <Row className="no-gutters">
                <Col
                  xs={{ size: 12, offset: 1 }}
                  sm={{ size: 6, offset: 7 }}
                  lg={{ size: 4 }}
                >
                  <h2 className="heading2">How We Find Value</h2>
                  <p className="body-copy">{page.acf_strategy.section3.text}</p>
                </Col>
              </Row>
              <Row>
                <ValueLine />
              </Row>
            </div>
          </Container>

          <footer className="entry-footer" />
        </article>
      </Layout>
    </Parallax>
  );
};

export default Strategy;

export const query = graphql`
  query {
    wpgraphql {
      page(id: "cGFnZTo4") {
        title
        acf_page_meta {
          metadescription
        }
        acf_strategy {
          heroHead
          heroSubhead
          heroText
          section1 {
            head
            text
            bulletsHead
            bullets {
              bullet
            }
            image {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed(width: 400, height: 266, quality: 90) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          section2 {
            head
            text
          }
          section3 {
            head
            text
            bullets {
              bullet
            }
            image {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed(width: 675, height: 450, quality: 90) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
